import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { serverURL } from '../constants';
import Header from '../components/header';
import Footers from '../components/footers';
const BlogPost = () => {
    const [blog, setBlog] = useState(null);
    const { slug } = useParams();

    useEffect(() => {
        if (slug) {
            const fetchBlog = async () => {
                try {
                    const response = await axios.get(serverURL + `/api/blog/${slug}`);
                    setBlog(response.data);
                } catch (error) {
                    console.error('Error fetching blog:', error);
                }
            };

            fetchBlog();
        }
    }, [slug]);

    if (!blog) return <div>Loading...</div>;

    return (
        <div className='h-screen flex flex-col'>
            <Header isHome={false} className="sticky top-0 z-50" />
            <div className="container mx-auto px-4 py-8">
                <h1 className="text-3xl font-bold mb-4">{blog.title}</h1>
                <p className="text-gray-600 mb-4">{new Date(blog.date).toLocaleDateString()}</p>
                {blog.image && (
                    <img src={blog.image} alt={blog.title} style={{maxWidth:"300px", margin:"auto"}} className=" object-cover mb-6" />
                )}
                <div className="prose max-w-none mt-5" dangerouslySetInnerHTML={{ __html: blog.content }} />
            </div>
            <Footers className="sticky bottom-0 z-50" />
        </div>
    );
};

export default BlogPost;