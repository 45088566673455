import React from 'react';
import slide from '../../res/img/slideOne.png'
import slideTwo from '../../res/img/slideTwo.png'
import { useNavigate } from "react-router-dom";
import { company } from '../../constants';
import {  FaChevronDown } from 'react-icons/fa';

const SlideOne = () => {

    const navigate = useNavigate();

    function redirectSignIn() {
        navigate("/signin");
    }
    function redirectSignUp() {
        navigate("/signup");
    }
    function redirectUpdate() {
        navigate("/update");
    }

    // New function to handle smooth scrolling
    const scrollToSlideTwo = () => {
        const slideTwoElement = document.getElementById('slideTwo');
        if (slideTwoElement) {
            slideTwoElement.scrollIntoView({ behavior: 'smooth' });
        }
    };

    

    return (
        <div className="flex flex-col items-center dark:bg-black home-holder">
            <div className="flex flex-col items-center justify-center" style={{ height: "90vh" }}>
                <h1 className="text-6xl font-black text-center max-md:text-3xl">
                    Join Learnooza
                </h1>

                <p className="text-center text-black mt-6 max-w-2xl font-medium max-md:text-xs">
                    We revolutionize your learning journey with AI.
                </p>

                <div className="flex space-x-4 mb-4 mt-6">
                    <button onClick={redirectSignIn} className="border-black text-black border px-3 py-2 font-medium">
                        Sign In
                    </button>
                    <button onClick={redirectSignUp} className="bg-black text-white px-3 py-2 font-medium">
                        Sign Up
                    </button>
                </div>

                <button
                    onClick={redirectUpdate}
                    className="fixed bottom-4 right-4 border-black text-black border px-3 py-2 font-medium rounded-full shadow-lg hover:bg-black hover:text-white transition-colors duration-300 z-50"
                >
                    Tap in {company} for Good Luck!
                </button>

            </div>
            <div
                style={{ marginTop: "-80px" }} className=" cursor-pointer animate-bounce"
                onClick={scrollToSlideTwo}
            >
                <FaChevronDown size={32} />
            </div>


          
        </div>
    );
};

export default SlideOne;