import React from "react";
import { PiKeyboard, PiVideo, PiDownloadBold } from "react-icons/pi";
import { RiAiGenerate } from "react-icons/ri";
import { FaQuestionCircle, FaInfinity, FaRobot, FaBookOpen } from "react-icons/fa";

const SlideThree = () => {
    return (
        <div className="dark:bg-black">
            <div className="flex items-center justify-center text-center">
                <div className="text-4xl font-black dark:text-white max-md:text-2xl">How it works</div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 py-24 max-md:py-14 text-center">
                <div className="flex flex-col items-center justify-center">
                    <PiKeyboard className="text-4xl max-md:text-3xl dark:text-white" />
                    <div className="text-2xl max-md:text-lg pt-4 font-bold dark:text-white">Enter Course Title</div>
                    <div className="text-lg max-md:text-xs text-center px-3 pt-2 font-medium dark:text-white">Enter the course title for which you want to generate content using AI</div>
                </div>

                <div className="flex flex-col items-center justify-center">
                    <RiAiGenerate className="text-4xl max-md:text-3xl dark:text-white" />
                    <div className="text-2xl max-md:text-lg pt-4 font-bold dark:text-white">AI Generates Sub-Topic</div>
                    <div className="text-lg max-md:text-xs text-center px-3 pt-2 font-medium dark:text-white">AI will generate topics and subtopics based on the title you provide</div>
                </div>

                <div className="flex flex-col items-center justify-center">
                    <PiVideo className="text-4xl max-md:text-3xl dark:text-white" />
                    <div className="text-2xl max-md:text-lg pt-4 font-bold dark:text-white">Video & Theory Course</div>
                    <div className="text-lg max-md:text-xs text-center px-3 pt-2 font-medium dark:text-white">AI will pick the right image and video theory courses for you.</div>
                </div>

                <div className="flex flex-col items-center justify-center">
                    <PiDownloadBold className="text-4xl max-md:text-3xl dark:text-white" />
                    <div className="text-2xl max-md:text-lg pt-4 font-bold dark:text-white">Share or Download Your Course</div>
                    <div className="text-lg max-md:text-xs text-center px-3 pt-2 font-medium dark:text-white">You can share your course to other users and download it.</div>
                </div>

                <div className="flex flex-col items-center justify-center">
                    <FaQuestionCircle className="text-4xl max-md:text-3xl dark:text-white" />
                    <div className="text-2xl max-md:text-lg pt-4 font-bold dark:text-white">Get Tested With Qualified Questions</div>
                    <div className="text-lg max-md:text-xs text-center px-3 pt-2 font-medium dark:text-white">Assess your knowledge with high-quality, curated questions</div>
                </div>

                <div className="flex flex-col items-center justify-center">
                    <FaInfinity className="text-4xl max-md:text-3xl dark:text-white" />
                    <div className="text-2xl max-md:text-lg pt-4 font-bold dark:text-white">Generate Infinite Tests</div>
                    <div className="text-lg max-md:text-xs text-center px-3 pt-2 font-medium dark:text-white">Use AI Quiz to generate unlimited tests on any subject</div>
                </div>

                <div className="flex flex-col items-center justify-center">
                    <FaRobot className="text-4xl max-md:text-3xl dark:text-white" />
                    <div className="text-2xl max-md:text-lg pt-4 font-bold dark:text-white">Ask Your Personal 24/7 AI</div>
                    <div className="text-lg max-md:text-xs text-center px-3 pt-2 font-medium dark:text-white">Get answers and assistance anytime with your AI companion</div>
                </div>

                <div className="flex flex-col items-center justify-center">
                    <FaBookOpen className="text-4xl max-md:text-3xl dark:text-white" />
                    <div className="text-2xl max-md:text-lg pt-4 font-bold dark:text-white">Ask Learnooza AI Anything</div>
                    <div className="text-lg max-md:text-xs text-center px-3 pt-2 font-medium dark:text-white">Inquire about any subject or question using Learnooza AI</div>
                </div>
            </div>
        </div>
    );
};

export default SlideThree;