import { Navbar } from 'flowbite-react';
import React, { useEffect, useState } from 'react';
import { FiMenu, FiX } from 'react-icons/fi';
import AdminSidebar from './components/adminsidebar';
import AdminHead from './components/adminhead';
import AdminSidebarMobile from './components/adminsidebarmobile';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { serverURL } from '../constants';

const AdminBlogListing = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [blogs, setBlogs] = useState([]);

    useEffect(() => {
        sessionStorage.setItem('darkMode', false);
        fetchBlogs();
    }, []);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const fetchBlogs = async () => {
        try {
            const response = await axios.get(serverURL + '/api/blog');
            setBlogs(response.data);
        } catch (error) {
            console.error('Error fetching blogs:', error);
        }
    };

    const deleteBlog = async (id) => {
        if (window.confirm('Are you sure you want to delete this blog post?')) {
            try {
                await axios.delete(`${serverURL}/api/blog/${id}`);
                fetchBlogs(); // Refresh the list after deletion
            } catch (error) {
                console.error('Error deleting blog:', error);
            }
        }
    };

    const BlogTable = () => (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold mb-6">Manage Blogs</h1>
            <Link to="/admin/blog/create" className="bg-black text-white px-4 py-2 rounded mb-4 inline-block">
                Create New Blog
            </Link>
            <table className="w-full border-collapse">
                <thead>
                    <tr className="bg-gray-200">
                        <th className="border p-2">Title</th>
                        <th className="border p-2">Date</th>
                        <th className="border p-2">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {blogs.map((blog) => (
                        <tr key={blog._id}>
                            <td className="border p-2">{blog.title}</td>
                            <td className="border p-2">{new Date(blog.date).toLocaleDateString()}</td>
                            <td className="border p-2">
                                <Link to={`/blog/${blog.slug}`} className="text-black mr-2">
                                    View
                                </Link>
                                <button onClick={() => deleteBlog(blog._id)} className="text-red-500">
                                    Delete
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );

    return (
        <>
            <div>
                <div className="flex bg-white dark:bg-black md:hidden pb-10 overflow-y-auto">
                    <div className={`fixed inset-0 bg-black opacity-50 z-50 ${isSidebarOpen ? 'block' : 'hidden'}`} onClick={toggleSidebar}></div>
                    <div className="flex-1 flex flex-col overflow-hidden">
                        <div>
                            <Navbar fluid className='py-3 dark:bg-black bg-white border-black dark:text-white dark:border-white md:border-b'>
                                <Navbar.Brand className='ml-1'>
                                    <p className='font-black text-xl'>Admin Panel</p>
                                </Navbar.Brand>
                                <div className='flex md:hidden justify-center items-center'>
                                    {isSidebarOpen ? (
                                        <FiX
                                            onClick={toggleSidebar}
                                            className='mx-2'
                                            size={20}
                                            color={sessionStorage.getItem('darkMode') === 'true' ? 'white' : 'black'}
                                        />
                                    ) : (
                                        <FiMenu
                                            onClick={toggleSidebar}
                                            className='mx-2'
                                            size={20}
                                            color={sessionStorage.getItem('darkMode') === 'true' ? 'white' : 'black'}
                                        />
                                    )}
                                </div>
                            </Navbar>
                            <BlogTable />
                        </div>
                        <AdminSidebarMobile isSidebarOpen={isSidebarOpen} />
                    </div>
                </div>
                <div className='flex flex-row overflow-y-auto h-screen max-md:hidden no-scrollbar'>
                    <AdminSidebar />
                    <div className='overflow-y-auto flex-grow flex-col dark:bg-black'>
                        <AdminHead />
                        <BlogTable />
                    </div>
                </div>
            </div>
        </>
    );
};

export default AdminBlogListing;