import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { serverURL } from '../constants';

const BlogGrid = () => {
    const [blogs, setBlogs] = useState([]);

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response = await axios.get( serverURL+ '/api/blog');
                setBlogs(response.data);
            } catch (error) {
                console.error('Error fetching blogs:', error);
            }
        };

        fetchBlogs();
    }, []);

    return (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {blogs.map((blog) => (
                <Link to={`/blog/${blog.slug}`} key={blog._id}>
                    <div className="border rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition-shadow duration-300">
                        {blog.image && (
                            <img src={blog.image} alt={blog.title} className="w-full h-48 object-cover" />
                        )}
                        <div className="p-4">
                            <h2 className="text-xl font-semibold mb-2">{blog.title}</h2>
                            <p className="text-gray-600 mb-2">{new Date(blog.date).toLocaleDateString()}</p>
                            <p className="text-gray-700 truncate">{blog.content.substring(0, 100)}...</p>
                        </div>
                    </div>
                </Link>
            ))}
        </div>
    );
};

export default BlogGrid;