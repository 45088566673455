//BRAND
export const name = 'Learnooza';
export const company = 'Learnooza';
export const websiteURL = 'https://www.learnooza.com/';
export const serverURL = 'https://learningwithjzai.com';
// export const serverURL = 'http://localhost:5000';
export const logo = 'https://firebasestorage.googleapis.com/v0/b/courses-5b143.appspot.com/o/email-logo.png?alt=media&token=367c5cae-1f27-4621-a9d0-65b7addbd6ce'
export const emailLogo='https://firebasestorage.googleapis.com/v0/b/courses-5b143.appspot.com/o/email-logo.png?alt=media&token=367c5cae-1f27-4621-a9d0-65b7addbd6ce';
export const razorpayEnabled = false;
export const paypalEnabled = true;

//PRICING :-
        
//FREE 
export const FreeType = 'Free Plan';
export const FreeCost = 0;
export const FreeTime = '';

//MONTHLY 
export const MonthType = 'Monthly Plan';
export const MonthCost = 3;
export const MonthTime = 'month';

//YEARLY 
export const YearType = 'Yearly Plan';
export const YearCost = 33;
export const YearTime = 'year';

//TESTIMONIAL
export const review = "The AI Course Generator revolutionized my content creation process, providing accurate and relevant topics effortlessly. It's a time-saving powerhouse that enhances the quality and relevance of my courses. A must-have tool for educators seeking efficiency and impactful online learning experiences.";
export const from = "Anam Meena Sharif";
export const profession = 'CFO at Learnooza';
export const photoURL = 'https://play-lh.googleusercontent.com/sV_ffBmBJt_je4RZHnfaCfcnL-Hy6C14Iol7H5EMj9fzI2GDOonuojdn5t9p6n9IAX8j';

//PAYPAL
export const paypalPlanIdOne = "P-28H79291XA652600GMZLKKHY";
export const paypalPlanIdTwo = "P-0F009356N28329610MZT6KVQ";

//RAZORPAY
export const razorpayKeyId = "rzp_test_uqALjZHyTyuiOm";
export const razorpayPlanIdOne = "plan_NMvvtDfznbRp6V";
export const razorpayPlanIdTwo = "plan_NMRc9HARBQRLWA";