import React from 'react';
import { Avatar, Blockquote, Rating } from 'flowbite-react';
import Slider from 'react-slick';
import { review, from, photoURL, profession } from '../../constants';

// Import CSS files for react-slick
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const testimonials = [
    {
        name: "Ariana Gonzalez",
        occupation: "Elementary teacher in Colorado USA",
        testimonial: "The AI Course Generator has transformed how I create content, effortlessly suggesting precise and pertinent subjects. This tool is a game-changer for saving time and boosting the caliber and pertinence of my courses. It's an essential asset for educators aiming to streamline their workflow and deliver meaningful online educational content.",
        rate: 5
    },
    {
        name: "Martin Ma",
        occupation: "Keystone Academy, Beijing, China",
        testimonial: "Learnooza has been a fantastic resource for my studies. The personalized courses and quizzes, along with the effective planning tools, have helped me achieve better grades and manage my time efficiently.",
        rate: 5
    },
    {
        name: "Aisha Ahmed",
        occupation: "International School of Geneva, Switzerland",
        testimonial: "Learnooza has revolutionized my approach to learning. The platform's customized courses and quizzes, coupled with excellent time management features, have significantly boosted my grades and overall academic performance.",
        rate: 5
    },
    {
        name: "Alex Johnson",
        occupation: "Harvard-Westlake School, Los Angeles, USA",
        testimonial: "Learnooza has transformed my learning experience! The platform's courses and quizzes are tailored to my needs, and its planning tools have significantly improved my grades and time management.",
        rate: 4.5
    },
    {
        name: "Priya Kumar",
        occupation: "Sydney Grammar School, Sydney, Australia",
        testimonial: "With Learnooza, I can easily track my progress and stay on top of my studies. The personalized courses and quizzes have been incredibly helpful in boosting my grades and managing my time effectively.",
        rate: 5
    },
    {
        name: "Carlos Martinez",
        occupation: "Eton College, Windsor, UK",
        testimonial: "Learnooza's learning platform is a game-changer! The custom courses and quizzes, along with the planning features, have greatly enhanced my learning experience, leading to better grades and improved time management.",
        rate: 4.5
    },
    {
        name: "Mei Ling",
        occupation: "Raffles Institution, Singapore",
        testimonial: "I love how Learnooza makes learning so efficient. The courses and quizzes are spot on, and the scheduling tools have helped me manage my time better, resulting in improved grades.",
        rate: 5
    },
    {
        name: "Liam O'Connor",
        occupation: "St. Michael's College, Dublin, Ireland",
        testimonial: "Learnooza has been instrumental in my academic success. The platform's well-designed courses and quizzes, combined with effective planning tools, have helped me achieve better grades and master time management.",
        rate: 5
    }
];

const SlideFive = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
    };

    return (
        <div className='dark:bg-black'>
            <Slider {...settings}>
                {testimonials.map((testimonial, index) => (
                    <div key={index}>
                        <figure className="max-w-screen-md mx-auto py-16 flex flex-col items-center dark:bg-black">
                            <div className="mb-4 flex items-center">
                                <Rating size="md">
                                    {[...Array(5)].map((_, i) => (
                                        <Rating.Star
                                            key={i}
                                            color={i < Math.floor(testimonial.rate) ? 'black' : 'gray'}
                                            className='dark:fill-white'
                                        />
                                    ))}
                                </Rating>
                            </div>
                            <Blockquote>
                                <p className="text-1xl max-md:text-lg max-md:px-2 font-bold text-black dark:text-white text-center">
                                    {testimonial.testimonial}
                                </p>
                            </Blockquote>
                            <figcaption className="mt-6 flex items-center space-x-3">
                                <Avatar rounded size="xs" img={photoURL} alt="profile picture" />
                                <div className="flex items-center divide-x-2 divide-black dark:divide-white">
                                    <cite className="pr-3 font-bold text-black dark:text-white">{testimonial.name}</cite>
                                    <cite className="pl-3 text-sm font-normal text-black dark:text-white">{testimonial.occupation}</cite>
                                </div>
                            </figcaption>
                        </figure>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default SlideFive;
