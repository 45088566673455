import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { serverURL } from '../constants';
import axios from 'axios';
import Loader from '../components/loader';
import favicon from '../res/img/favicon.ico';

const ModalButton = styled.button`
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  width: 80%;
  max-width: 500px;
  max-height: 80%;
  display: flex;
  flex-direction: column;
`;

const CloseButton = styled.button`
  align-self: flex-end;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
`;

const ChatContainer = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  margin-bottom: 10px;
  max-height: 300px;
`;

const Message = styled.div`
  margin-bottom: 10px;
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  ${props => props.type === 'user' ? `
    background-color: #e6f2ff;
    justify-content: flex-end;
  ` : `
    background-color: #f0f0f0;
    justify-content: flex-start;
  `}
`;

const MessageContent = styled.div`
  ${props => props.type === 'user' ? `
    order: -1;
  ` : `
    order: 1;
  `}
`;


const Form = styled.form`
  display: flex;
`;

const Input = styled.input`
  flex-grow: 1;
  margin-right: 10px;
  padding: 5px;
  font-size: 16px;
`;

const SendButton = styled.button`
`;
const removeAsterisks = (text) => {
  return text.replace(/\*/g, '');
};
const AiModal = ({ courseTopic }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const messagesEndRef = useRef(null);

  const toggleModal = () => setIsOpen(!isOpen);

  const handleInputChange = (e) => setInputValue(e.target.value);



  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(scrollToBottom, [messages]);

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputValue.trim() === '') return;
    let topic = sessionStorage.getItem('courseTopic');
    let prompt = "You are expert in topic: '" + topic + " , answer the query precisely.Strictly don't  use * or asterisk in response, strictly respond in html,css format  only and do not add  '```html' in response . Don't use h1, h2, h3 tags strictly. Use only <ul> for list.  Use bold for highlights and title. If asked for your inro then call    'Learnooza AI'";
    // let aiPrompt = "Query="+inputValue+" "+prompt;

    // Reverse the order of messages
    const reversedMessages = [...messages].reverse();

 

    // Combine history prompt with user's query
    // if (historyPrompt.trim() !== '') {
    //   aiPrompt = aiPrompt + " \n Here are your previous replies: " + historyPrompt;
    // }
    const jsonStructure =JSON.stringify( {
      topic: topic,
      query: inputValue,
      prompt: prompt,
      chatHistory: reversedMessages.filter(message => message.type === 'ai')
    });
    console.log(jsonStructure);
    const userMessage = { type: 'user', content: inputValue };
    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setInputValue('');
    setIsLoading(true);

    try {
      const postURL = serverURL + '/api/gpt-prompt';
      const dataToSend = { prompt: jsonStructure }; // Adjust the data structure according to your API requirements
      const res = await axios.post(postURL, dataToSend);
      const generatedText = res.data.generatedText;

      const aiMessage = { type: 'ai', content: generatedText };
      setMessages((prevMessages) => [...prevMessages, aiMessage]);
    } catch (error) {
      console.error('Error fetching AI response:', error);
      // Optionally, handle the error in the UI
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <ModalButton className='bg-black text-white px-3 py-2 font-medium dark:bg-white dark:text-black fixed bottom-4 right-20 rounded-full' onClick={toggleModal}> 
     Ask AI</ModalButton>
      {isOpen && (
        <ModalOverlay>
          <ModalContent >
            <CloseButton onClick={toggleModal}>&times;</CloseButton>
            <ChatContainer >
              {messages.map((message, index) => (
                <Message key={index} type={message.type}>
                  {message.type === 'ai' && (
                    <img
                      src={favicon}
                      alt="AI Logo"
                      className="ai-logo"
                      style={{
                        width: '24px',
                        height: '24px',
                        borderRadius: '50%',
                        marginRight: '8px',
                        backgroundColor:"#fff",
                      }}
                    />
                  )}
                  <MessageContent type={message.type} className={message.type} >
                    {message.type === 'ai' ? (
                      <div dangerouslySetInnerHTML={{ __html: removeAsterisks(message.content) }} />
                    ) : (
                      message.content
                    )}
                  </MessageContent>
                </Message>
              ))}
              <div ref={messagesEndRef} />
            </ChatContainer>
            <Form onSubmit={handleSubmit}>
              <Input
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                placeholder="Type your message..."
              />
              
              <SendButton className='' type="submit">
                {isLoading ? <Loader /> : <img style={{width:'30px'}} width="48" height="48" src="https://img.icons8.com/sf-regular/48/paper-plane.png" alt="paper-plane" />}

              </SendButton>
            </Form>
          </ModalContent>
        </ModalOverlay>
      )}
    </>
  );
};

export default AiModal;