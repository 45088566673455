import React from 'react';
import BlogGrid from '../components/BlogGrid';
import Header from '../components/header';
import Footers from '../components/footers';
const BlogListingPage = () => {
    return (
        <div className='h-screen flex flex-col'>
            <Header isHome={false} className="sticky top-0 z-50" />
            <div className="container mx-auto px-4 py-8">
                <h1 className="text-3xl font-bold mb-6">Blog Posts</h1>
                <BlogGrid />
            </div>
            <Footers className="sticky bottom-0 z-50" />
        </div>
    );
};

export default BlogListingPage;