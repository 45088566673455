import React from 'react';
import image1 from '../../res/img/unlock-image-1.png';
import image2 from '../../res/img/unlock-image-2.png';
import image3 from '../../res/img/unlock-image-3.png';
import { FaUsers, FaBook, FaQuestionCircle, FaComments, FaChevronDown } from 'react-icons/fa';

const SlideTwo = () => {
    const stats = [
        { label: 'Active Users', value: '700+', icon: <FaUsers /> },
        { label: 'Courses Created', value: '1500+', icon: <FaBook /> },
        { label: 'Quizzes Created', value: '4000+', icon: <FaQuestionCircle /> },
        { label: 'Conversations Created', value: '10000+', icon: <FaComments /> },
    ];
    return (

        <div className="px-7 mt-20 justify-center items-center pb-10 dark:bg-black">
            <div id="slideTwo" className="flex flex-col items-center justify-center dark:bg-black py-16 dark:text-white" style={{ minHeight: "70vh" }}>
                <div className="grid grid-cols-2 md:grid-cols-4 gap-12 w-full max-w-7xl px-8">
                    {stats.map((stat, index) => (
                        <div key={index} className="text-center flex flex-col items-center">
                            <div className="text-7xl mb-4">{stat.icon}</div>
                            <div className="text-5xl font-bold mb-2">{stat.value}</div>
                            <div className="text-xl mt-2">{stat.label}</div>
                        </div>
                    ))}
                </div>

                {/* Uncomment if you want to include images */}
                {/* <div className="mx-auto w-full max-w-7xl grid grid-cols-1 md:grid-cols-2 gap-8 mt-16 px-8">
        <img src={slide} className="w-full object-cover h-96" alt="Slide 1" />
        <img src={slideTwo} className="w-full object-cover h-96" alt="Slide 2" />
    </div> */}
            </div>
            <h2 className="text-4xl font-black mb-10 text-center max-md:text-2xl dark:text-white">
                Unlock The New Way Of Learning
            </h2>

            {/* Section 1 */}
            <div className="flex flex-col md:flex-row items-center mb-20">
                <div className="md:w-1/2 h-full p-4 flex flex-col items-center md:items-start justify-center">
                    <h3 className="text-2xl font-bold mb-4 max-md:text-xl dark:text-white">
                        Newly Integrated Course Generation
                    </h3>
                    <p className="text-black mb-2 mt-2 max-md:text-center max-md:text-sm dark:text-white">
                        Course Displayed With PEE (Point Example Evidence) Format, Integrated With Video Lectures Will Foster Your Learning Experience.


                    </p>
                </div>
                <div className="md:w-1/2 h-full">
                    <img
                        src={image1}
                        alt="Newly Integrated Course Generation"
                        className="w-full h-full object-cover"
                    />
                </div>
            </div>

            {/* Section 2 */}
            <div className="flex flex-col md:flex-row-reverse items-center mb-20">
                <div className="md:w-1/2 h-full p-4 flex flex-col items-center md:items-start justify-center">
                    <h3 className="text-2xl font-bold mb-4 max-md:text-xl dark:text-white">
                        Automated AI Quiz
                    </h3>
                    <p className="text-black mb-2 mt-2 max-md:text-center max-md:text-sm dark:text-white">
                        With Our Well-Trained AI, We Can Generate Infinite Quizzes And Answers Within Seconds That Will Meet Your Expectations.
                    </p>
                </div>
                <div className="md:w-1/2 h-full">
                    <img
                        src={image2}
                        alt="Automated AI Quiz"
                        className="w-full h-full object-cover"
                    />
                </div>
            </div>

            {/* Section 3 */}
            <div className="flex flex-col md:flex-row items-center">
                <div className="md:w-1/2 h-full p-4 flex flex-col items-center md:items-start justify-center">
                    <h3 className="text-2xl font-bold mb-4 max-md:text-xl dark:text-white">
                        24-Hour Private AI Learning Assistant
                    </h3>
                    <p className="text-black mb-2 mt-2 max-md:text-center max-md:text-sm dark:text-white">
                        Our AI Covers Any Topics. It Also Remembers Your Course And Will Answer Every Question, Anytime Anywhere.
                    </p>
                </div>
                <div className="md:w-1/2 h-full">
                    <img
                        src={image3}
                        alt="24-Hour Private AI Learning Assistant"
                        className="w-full h-full object-cover"
                    />
                </div>
            </div>
        </div>
    );
};

export default SlideTwo;